.light {
  text-align: center;
  &.App {
    text-align: center;
  }
  &.App-logo {
    pointer-events: none;
  }
  &.App-header {
    min-height: 40vh;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #252f40;
  }
  &.App-link {
    color: #61dafb;
  }
}

.dark {
  text-align: center;
  &.App {
    flex: auto;
    height: 100vh;
    text-align: center;
    background-color: #252f40;
  }
  &.App-logo {
    pointer-events: none;
    background-color: inherit;
  }
  &.App-header {
    background-color: inherit;
    min-height: 40vh;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #f0f0f0;
  }
  &.App-link {
    color: #61dafb;
  }
}

.link {
  -webkit-tap-highlight-color: transparent;
}

.Badge {
  max-width: 50vw;
  min-width: 50vw;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #252f40;
}

.App-link {
  color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo-spin {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
